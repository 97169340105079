import { Breakpoint, ThemeFontWeight } from '@/enums'
import {
  returns,
  themeBreakpointDown,
  themeColorFrom,
  themeFontWeight,
  toThemePixels,
} from '@/utils'
import { ifElse, pipe } from 'ramda'
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'

const ellipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const commonStyles = css`
  color: ${themeColorFrom(prop('color'))};
  cursor: ${ifProp('onClick', 'pointer', 'inherit')};
  white-space: pre-line;
  line-height: ${ifProp('singleLine', '100%', '150%')};
  text-align: ${prop('textAlign')};
  word-break: ${ifProp('breakWord', 'break-word')};
  text-decoration: ${ifProp('underline', 'underline')};
  ${ifProp('ellipsis', ellipsis)};
`

const makeThemeFontWeight = (font) =>
  pipe(
    ifElse(prop('bold'), returns(ThemeFontWeight.bold), returns(font)),
    themeFontWeight,
  )

// Deprecated styles

const h1MobileStyles = css`
  ${themeBreakpointDown(Breakpoint.sm)} {
    font-size: ${toThemePixels(8)};
  }
`

const h2MobileStyles = css`
  ${themeBreakpointDown(Breakpoint.sm)} {
    font-size: ${toThemePixels(7)};
  }
`

const h3MobileStyles = css`
  ${themeBreakpointDown(Breakpoint.sm)} {
    font-size: ${toThemePixels(6)};
  }
`

const h4MobileStyles = css`
  ${themeBreakpointDown(Breakpoint.sm)} {
    font-size: ${toThemePixels(4.5)};
  }
`

const h5MobileStyles = css`
  ${themeBreakpointDown(Breakpoint.sm)} {
    font-size: ${toThemePixels(4)};
  }
`

export const H1 = styled.h1`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.medium)};
  font-size: ${toThemePixels(16)};

  ${ifProp('responsive', h1MobileStyles)}
`

export const H2 = styled.h2`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.medium)};
  font-size: ${toThemePixels(10)};
  ${ifProp('responsive', h2MobileStyles)}
`

export const H3 = styled.span`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.medium)};
  font-size: ${toThemePixels(7.5)};
  ${ifProp('responsive', h3MobileStyles)}
`

export const H4 = styled.span`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.medium)};
  font-size: ${toThemePixels(6)};
  ${ifProp('responsive', h4MobileStyles)}
`

export const H5 = styled.h5`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.medium)};
  font-size: ${toThemePixels(5)};
  ${ifProp('responsive', h5MobileStyles)}
`

export const H6 = styled.h6`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.regular)};
  font-size: ${toThemePixels(5)};
`

export const CaptionB = styled.span`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.medium)};
  font-size: ${toThemePixels(4)};
`

export const CaptionR = styled.span`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.medium)};
  font-size: ${toThemePixels(3.5)};
`

export const P1Body = styled.span`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.regular)};
  font-size: ${toThemePixels(4)};
`

export const P2Body = styled.span`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.regular)};
  font-size: ${toThemePixels(3.5)};
`

export const Button = styled.span`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.medium)};
  font-size: ${toThemePixels(4)};
`

export const placeholderStyles = css`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.regular)};
  font-size: ${toThemePixels(4)};
`

export const Placeholder = styled.span`
  ${placeholderStyles}
`

export const InputDescription = styled.span`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.regular)};
  font-size: ${toThemePixels(3)};
`

export const InputLabel = styled.label`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.medium)};
  font-size: ${toThemePixels(3.5)};

  margin: 0;
`

export const BadgeText = styled.span`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.regular)};
  font-size: ${toThemePixels(3)};
`

// End of deprecated styles

// New design system styles
export const PageTitle = styled.h1`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.semibold)};
  font-size: 64px;
`

export const SectionTitle = styled.h2`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.semibold)};
  font-size: 40px;
`

export const ItemTitle = styled.span`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.semibold)};
  font-size: 24px;
`

export const Subheadline = styled.span`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.medium)};
  font-size: 21px;
`

export const Body = styled.span`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.medium)};
  font-size: 17px;
`

export const Description = styled.span`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.medium)};
  font-size: 15px;
`

export const Footnote = styled.span`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.medium)};
  font-size: 14px;
`

export const Micro = styled.span`
  ${commonStyles};
  font-weight: ${makeThemeFontWeight(ThemeFontWeight.medium)};
  font-size: 12px;
`
