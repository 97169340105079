import { Column, Row, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { theme } from '@/theme'
import { themeBreakpointDown } from '@/utils'
import {
  Coffee,
  CopyCheck,
  Crown,
  HeartHandshake,
  LucideProps,
} from 'lucide-react'
import { ComponentType } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Container = styled(Column)`
  justify-content: center;
  text-align: center;
  gap: 32px;
  position: relative;
`

const FeatureItem = styled(Column)`
  align-items: center;
  text-align: center;
  flex-basis: calc(25% - 24px);

  ${themeBreakpointDown('sm')} {
    flex-basis: calc(50% - 10px);
  }
`

const sureinUsps = ['simple', 'protection', 'independent', 'free']

const sureinUspsIconMap: { [key: string]: ComponentType<LucideProps> } = {
  simple: Coffee,
  protection: HeartHandshake,
  independent: CopyCheck,
  free: Crown,
}

export const WhySurein = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <Typography variant="itemTitle">
        {t('recommendationFeaturesTitle')}
      </Typography>
      <Row gap="32px" justifyContent="center" wrap="wrap">
        {sureinUsps.map((uspKey, index) => {
          const Icon = sureinUspsIconMap[uspKey]

          return (
            <FeatureItem key={index}>
              <Icon color={theme.color.info1} size={24} />
              <Column gap="4px">
                <Typography variant="body">
                  {t(`sureinUsps.${uspKey}.title`)}
                </Typography>
                <Typography color={ThemeColor.b50} variant="description">
                  {t(`sureinUsps.${uspKey}.description`)}
                </Typography>
              </Column>
            </FeatureItem>
          )
        })}
      </Row>
    </Container>
  )
}
