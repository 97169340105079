import { api, newApi } from './api'
import z from 'zod'
import { validateAxiosResponse } from '@/utils/requestValidation'

// Define the FileCategory as an enum in Zod
const FileCategorySchema = z.enum([
  'contract',
  'offer',
  'application',
  'invoice',
  'incident',
  'claim',
  'cover_note',
  'contribution_arrears',
])

// Define the GetInsuranceApiResponse schema
const GetInsuranceApiResponseSchema = z.object({
  insurance_label: z.string().nullable(),
  payment_period: z
    .enum([
      'monthly',
      'quarterly',
      'half-yearly',
      'yearly',
      'once',
      'non-contributory',
    ])
    .nullable(),
  gross_price: z.number().nullable(),
  overallRating: z.number().nullable(),
  name: z.string().nullable(),
  policy_number: z.string().nullable(),
  amount_insured_unlimited: z.boolean(),
  amount_insured: z.number().nullable(),
  parameters_values: z
    .object({
      en: z.record(z.string()),
      de: z.record(z.string()),
    })
    .nullable(),
  yes_no_values: z.record(z.union([z.literal(0), z.literal(1)])).nullable(),
  carrier: z.object({
    name: z.string(),
    logo_url: z.string().nullable(),
    customer_satisfaction_rating: z.number().nullable(),
    response_time_rating: z.number().nullable(),
    claim_handling_rating: z.number().nullable(),
  }),
  location: z.object({
    name: z.string(),
  }),
  files: z.array(
    z.object({
      name: z.string(),
      display_name: z.string(),
      created_at: z.string(),
      /**
       * We shouldn't need this in the response.
       * FIXME: BE should return files that are visible to the user
       */
      visible_to_user: z.boolean(),
      category: FileCategorySchema.nullable(),
    }),
  ),
})

// TODO: fix type
type PostInsuranceApiResponse = any

export const getInsuranceById = async (id: string) =>
  validateAxiosResponse(
    GetInsuranceApiResponseSchema,
    newApi.get(`/insurance/${id}`),
  )

// TODO: fix type
export const createMultipleInsurances = (data: any) =>
  api.post<PostInsuranceApiResponse>('/insurance', data)
