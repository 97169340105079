type TestConfig = { weights: [number, number] } | Record<string, never>

export const ONGOING_TESTS = {
  SSFRecommendation2410: 'SSFrecommendation2410',
  businessTypeSelectorSort2410: 'businessTypeSelectorSort2410',
} as const

export const ONGOING_TESTS_CONFIG: Record<string, TestConfig> = {
  [ONGOING_TESTS.SSFRecommendation2410]: {
    weights: [0.5, 0.5],
  },
  [ONGOING_TESTS.businessTypeSelectorSort2410]: {
    weights: [0.5, 0.5],
  },
} as const
