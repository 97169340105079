export const insuranceGroupsWithKeys = [
  {
    groupName: 'mostPopular',
    keys: ['CA'],
  },
  {
    groupName: 'propertyProtection',
    keys: [
      'BL',
      'PR',
      'BU',
      'WD',
      'GL',
      'HO',
      'RL',
      'SB',
      'CO',
      'RE',
      'AR',
      'MU',
    ],
  },
  {
    groupName: 'financialSecurity',
    keys: ['LO', 'LP', 'PO', 'EM', 'BS', 'IN'],
  },
  {
    groupName: 'liabilityAndLegal',
    keys: ['PL', 'HU', 'FL', 'LI', 'LE', 'DO', 'OL', 'ML'],
  },
  {
    groupName: 'logisticsAndTransportation',
    keys: ['CA', 'TR', 'BI', 'CR', 'TA'],
  },
  {
    groupName: 'pension',
    keys: ['BP', 'BC', 'DI'],
  },
  {
    groupName: 'healthAndWellness',
    keys: [
      'HI',
      'LF',
      'DS',
      'SU',
      'DH',
      'OD',
      'BA',
      'DR',
      'DB',
      'HE',
      'SH',
      'CH',
      'SP',
      'LU',
      'LL',
      'LC',
      'TL',
    ],
  },
  {
    groupName: 'travel',
    keys: ['TH', 'BG', 'TC'],
  },
  {
    groupName: 'animal',
    keys: ['AO', 'AH'],
  },
  {
    groupName: 'specializedProtection',
    keys: [
      'CY',
      'EL',
      'EV',
      'EX',
      'FU',
      'CM',
      'WA',
      'CC',
      'AI',
      'AP',
      'AM',
      'MI',
      'TE',
      'AL',
      'SV',
    ],
  },
]
