import {
  GetRecommendationByIdApiResponse,
  GetSelfAssessmentApiResponse,
} from '@/api'
import {
  Column,
  ImageFromCdn,
  ModalBase,
  Typography,
  useImageUrlFromCdn,
} from '@/components'
import { config } from '@/config'
import { ThemeColor } from '@/enums'
import { useComparisonTable } from '@/pages/RecommendationPage/hooks/useComparisonTable'
import { useRiskAdditionalInfo } from '@/pages/RecommendationPage/hooks/useRiskAdditionalInfo'
import { useContactModal } from '@/providers'
import { useAttributes } from '@/services/attributes'
import { useLocationTypes } from '@/services/locationTypes'
import { theme } from '@/theme'
import { openInNewTab, useIsMobile, useModal } from '@/utils'
import { useCategoryTranslations } from '@/utils/hooks/useCategoryTranslations'
import {
  Button,
  Carousel,
  ComparisonTable,
  ComparisonTableWithSelect,
  ContactBanner,
  Skeleton,
} from '@surein/ui'
import { BadgeCheck, MessageCircleMore, User2 } from 'lucide-react'
import { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BookAppointment } from './BookAppointment'
import { CompaniesTrust } from './CompaniesTrust'
import { Faqs } from './Faqs'
import { IndependentBroker } from './IndependentBroker'
import { Products } from './Products'
import { TestimonialSection } from './TestimonialSection'
import { TopActions } from './TopActions'
import { WhatIsProtected } from './WhatIsProtected'
import { WhySurein } from './WhySurein'
import { DamageSampleCard } from './components/DamageSampleCard'
import {
  formatRiskAddress,
  prepareRecommendation,
  useMessages,
} from './helpers'
import {
  CollapsibleAdvisorContentWrapper,
  ComparisonModalImageContainer,
  ComparisonTableModalContent,
  ComparisonTableWithSelectContainer,
  ExamplesTitleContainer,
  FaqsWrapper,
  RecommendationAbsoluteWrapper,
  RecommendationCarouselCard,
  RecommendationTitle,
  RiskInsuredSpacer,
  RiskInsuredTypography,
  SizeContainer,
  WhatIsProtectedCard,
} from './styles'
import { useSegment } from '@/modules'

const RisksInsured = ({
  categoryId,
  assessmentValues = {},
}: {
  categoryId: string
  assessmentValues?: {
    businessType?: {
      locationType: string
      locationSubtypes: string[]
    }
    companyDetails?: {
      address: {
        street: string
        streetNumber?: string | undefined
        streetNr?: string | undefined
        postalCode: string
        city: string
      }
    }
  }
}) => {
  const { t } = useTranslation()
  const {
    data: locationTypesData,
    isLoading: locationTypesLoading,
  } = useLocationTypes()
  // As discussed with the BE, this component is not meant to last long
  // and will be replaced by a more dynamic BE handling in the future
  // @ts-expect-error TODO: fix this - no idea why this could be an array
  if (assessmentValues.length === 0) return null

  if (categoryId === 'CO' && !!assessmentValues?.companyDetails?.address) {
    return (
      <RiskInsuredTypography variant="p1Body">
        <Trans
          i18nKey="recommendationRisksInsured"
          values={{
            risk: formatRiskAddress(assessmentValues.companyDetails.address),
          }}
        />
      </RiskInsuredTypography>
    )
  }

  if (
    assessmentValues?.businessType?.locationType &&
    assessmentValues?.businessType?.locationSubtypes?.length > 0
  ) {
    return locationTypesLoading ? (
      <Skeleton center count={2} withParagraphEnding />
    ) : (
      <RiskInsuredTypography variant="p1Body">
        {t('analyzedOffers')}
        <br />
        <Trans
          count={assessmentValues.businessType.locationSubtypes.length}
          i18nKey="recommendationRisksInsuredWithSubtypes"
          values={{
            risk: locationTypesData?.getLocationLabel(
              assessmentValues.businessType.locationType,
            ),
            subtypes: assessmentValues.businessType.locationSubtypes
              .map((item) => locationTypesData?.getLocationLabel(item))
              .join(', '),
          }}
        />
      </RiskInsuredTypography>
    )
  }

  if (assessmentValues?.businessType?.locationType) {
    return locationTypesLoading ? (
      <Skeleton center count={2} withParagraphEnding />
    ) : (
      <RiskInsuredTypography variant="p1Body">
        {t('analyzedOffers')}
        <br />
        <Trans
          i18nKey="recommendationRisksInsured"
          values={{
            risk: locationTypesData?.getLocationLabel(
              assessmentValues.businessType.locationType,
            ),
          }}
        />
      </RiskInsuredTypography>
    )
  }

  return null
}

const collapsibleAdvisorContentIcons = [User2, BadgeCheck, MessageCircleMore]

interface CollapsibleAdvisorContentProps {
  advisorName: string
}

const CollapsibleAdvisorContent = ({
  advisorName,
}: CollapsibleAdvisorContentProps) => {
  const { t } = useTranslation()
  const content: string[] = t(
    `contactCarouselContent.${advisorName}.features`,
    {
      returnObjects: true,
    },
  )

  return (
    <Column gap="12px">
      {content.map((item, index) => {
        const Icon = collapsibleAdvisorContentIcons[index]
        return (
          <CollapsibleAdvisorContentWrapper key={item}>
            <Icon color={theme.color[ThemeColor.info1]} size={24} />
            <Typography key={content[index]} variant="p2Body">
              {item}
            </Typography>
          </CollapsibleAdvisorContentWrapper>
        )
      })}
    </Column>
  )
}

const MemoizedRisksInsured = memo(RisksInsured)

const handleArrangeMeeting = () => {
  openInNewTab(config.appointmentUrl)
}
interface ComparisonTableModalProps {
  isOpen: boolean
  close: () => void
  categoryId: string
  assessmentValues?: GetSelfAssessmentApiResponse['values']
  onChoose: (id: string) => void
  data: GetRecommendationByIdApiResponse
  handleFileClick: (file: { fileId: string; name: string }) => void
}

const ComparisonTableModal = ({
  isOpen,
  close,
  categoryId,
  assessmentValues,
  data,
  onChoose,
  handleFileClick,
}: ComparisonTableModalProps) => {
  const { width } = useIsMobile()
  const { t } = useTranslation()
  const { categoryKey } = useCategoryTranslations(data.categoryId)

  const insuranceCategory = t(`categories.${categoryKey}.name`)

  const { additionalInfo } = useRiskAdditionalInfo(
    assessmentValues,
    categoryKey,
  )

  const {
    isLoading: isLoadingAttributes,
    data: attributesData,
  } = useAttributes()

  const { items } = useComparisonTable(data, handleFileClick)

  if (isLoadingAttributes || !attributesData) {
    return null
  }

  const labels = [
    {
      label: t('insured_sum'),
      annotationText: t('info.amountInsured'),
    },
    {
      label: t('deductible'),
      annotationText: t('info.deductible'),
    },
    ...(data.visibleAttributes || []).map((attr) => ({
      label: attributesData.getAttributeLabel(attr),
      annotationText: attributesData.getAttributeDescription(attr),
    })),
    {
      label: t('insurance_details'),
    },
  ]

  return (
    <ModalBase
      padding="16px"
      isOpen={isOpen}
      closable
      onClose={close}
      fullscreen={width < 800}
    >
      <ComparisonTableModalContent>
        <Typography variant="itemTitle">{insuranceCategory}</Typography>
        <WhatIsProtectedCard variant="borderless">
          <WhatIsProtected categoryId={categoryId} smallTitle />
        </WhatIsProtectedCard>
        <ComparisonTableWithSelectContainer>
          <Typography variant="body">{t('recommendations.title')}</Typography>
          {width < 1024 ? (
            <ComparisonTableWithSelect
              additionalInfo={additionalInfo}
              items={items}
              labels={labels}
              onCTAClick={(selectedItem) => onChoose(selectedItem.id)}
              tableLabel={t('recommendations.title')}
            />
          ) : (
            <ComparisonTable
              additionalInfo={additionalInfo}
              items={items}
              labels={labels}
              onCTAClick={(selectedItem) => onChoose(selectedItem.id)}
              tableLabel={t('recommendations.title')}
            />
          )}
        </ComparisonTableWithSelectContainer>
        <WhatIsProtectedCard variant="borderless">
          <Column gap="32px" alignItems="center">
            <Column gap="8px">
              <Typography textAlign="center" variant="itemTitle">
                {t('comparisonModalCTATitle')}
              </Typography>
              <Typography
                textAlign="center"
                color={ThemeColor.b50}
                variant="description"
              >
                {t('comparisonModalCTASubtitle')}
              </Typography>
            </Column>
            <ComparisonModalImageContainer>
              <ImageFromCdn name="team-stack" type="webp" fallbackType="png" />
            </ComparisonModalImageContainer>
            <Button
              onClick={handleArrangeMeeting}
              variant="primary"
              shape="square"
            >
              {t('comparisonModalCTAButton')}
            </Button>
          </Column>
        </WhatIsProtectedCard>
      </ComparisonTableModalContent>
    </ModalBase>
  )
}

interface BasePageProps {
  assessmentValues?: GetSelfAssessmentApiResponse['values']
  bannerOffset?: number
  data: GetRecommendationByIdApiResponse
  handleFileClick: (file: { fileId: string; name: string }) => void
  noBack?: boolean
  onBack?: () => void
  onChoose: (id: string) => void
}

const contactCarouselKeys = ['daniel', 'jana', 'marvin']

export interface DamageSample {
  title: string
  subtitle: string
  price: string
  successRate: string
}

// eslint-disable-next-line import/no-unused-modules
export const BasePageB = ({
  assessmentValues,
  bannerOffset,
  data,
  onChoose,
  onBack,
  noBack,
  handleFileClick,
}: BasePageProps) => {
  const { t } = useTranslation()
  const messages = useMessages(data.categoryId)
  const {
    isLoading: isLoadingAttributes,
    data: attributesData,
  } = useAttributes()
  const { isMobile } = useIsMobile()

  const { isOpen, open, close } = useModal()
  const { openContact } = useContactModal()
  const analytics = useSegment()

  const { categoryKey } = useCategoryTranslations(data.categoryId)
  const { imageUrlFromCdn } = useImageUrlFromCdn({ type: 'webp' })

  if (isLoadingAttributes || !attributesData) {
    return null
  }

  const damageSamplesObject = t(`recommendationDamageSamples`, {
    returnObjects: true,
  }) as Record<string, DamageSample[]>

  const damageSamples = Object.keys(damageSamplesObject).includes(categoryKey)
    ? damageSamplesObject[categoryKey as keyof typeof damageSamplesObject]
    : damageSamplesObject['liability' as keyof typeof damageSamplesObject]

  const countdownTargetDateInMs =
    new Date(data.createdAt).getTime() + 3 * 24 * 60 * 60 * 1000

  const handleOpenDetailsProtectedSection = async () => {
    await analytics.track('click_details_section')
    open()
  }

  const handleArrangeMeetingButtonClick = async () => {
    await analytics.track('click_secure_discount')
    handleArrangeMeeting()
  }

  return (
    <RecommendationAbsoluteWrapper bannerOffset={bannerOffset}>
      <SizeContainer backgroundColor={ThemeColor.glass100} noSectionPadding>
        <TopActions
          categoryId={data.categoryId}
          noBack={noBack}
          onBack={onBack}
        />
        <Column gap="16px">
          <RecommendationTitle data-testid="recommendation-title">
            <Typography bold breakWord variant="h2">
              {messages.offerTitle}
            </Typography>
          </RecommendationTitle>
          <CompaniesTrust />
        </Column>
        <RiskInsuredSpacer>
          <MemoizedRisksInsured
            assessmentValues={assessmentValues}
            categoryId={data.categoryId}
          />
        </RiskInsuredSpacer>
        <Products
          data={prepareRecommendation(data)}
          onChoose={onChoose}
          openDetails={open}
          countdownTargetDateInMs={countdownTargetDateInMs}
        />
        <ComparisonTableModal
          categoryId={data.categoryId}
          close={close}
          data={data}
          assessmentValues={assessmentValues}
          handleFileClick={handleFileClick}
          isOpen={isOpen}
          onChoose={onChoose}
        />
        <BookAppointment
          targetDateInMs={countdownTargetDateInMs}
          onCtaClick={handleArrangeMeetingButtonClick}
        />
      </SizeContainer>
      <SizeContainer backgroundColor={ThemeColor.glass100}>
        <WhySurein />
      </SizeContainer>
      <SizeContainer backgroundColor={ThemeColor.b0}>
        <WhatIsProtected
          categoryId={data.categoryId}
          buttonText={t('compareDetails')}
          onButtonClick={handleOpenDetailsProtectedSection}
        />
      </SizeContainer>
      <SizeContainer backgroundColor={ThemeColor.glass100}>
        <ExamplesTitleContainer>
          <Typography textAlign="center" variant="itemTitle">
            {t('examplesFromPractice')}
          </Typography>
          <Typography
            color={ThemeColor.glass500}
            textAlign="center"
            variant="description"
          >
            {t('examplesFromPracticeDescription')}
          </Typography>
        </ExamplesTitleContainer>
        <Carousel slidesInView={isMobile ? 1 : 2} withArrows={!isMobile}>
          {damageSamples.map((sample, index) => {
            const imgSrc = imageUrlFromCdn(`${categoryKey}-${index + 1}`)
            return (
              <DamageSampleCard
                {...sample}
                key={sample.title}
                imgSrc={imgSrc}
              />
            )
          })}
        </Carousel>
      </SizeContainer>
      <SizeContainer noSectionPadding backgroundColor={ThemeColor.b0}>
        <IndependentBroker />
      </SizeContainer>
      <SizeContainer backgroundColor={ThemeColor.glass100}>
        <Carousel withArrows={!isMobile}>
          {contactCarouselKeys.map((key) => (
            <RecommendationCarouselCard noPadding key={key}>
              <ContactBanner
                buttonText={t('recommendations.contactBannerBtn')}
                collapsibleContent={
                  <CollapsibleAdvisorContent advisorName={key} />
                }
                collapsibleTriggerText={t(
                  'recommendations.contactBannerCollapsibleTriggerText',
                )}
                imageUrl={`${config.cdn}/${key}-headshot.webp`}
                onButtonClick={openContact}
                subtitle={t(`contactCarouselContent.${key}.subtitle`)}
                title={t(`contactCarouselContent.${key}.title`)}
              />
            </RecommendationCarouselCard>
          ))}
        </Carousel>
      </SizeContainer>
      <SizeContainer backgroundColor={ThemeColor.glass100}>
        <TestimonialSection isMobile={isMobile} />
      </SizeContainer>
      <FaqsWrapper>
        <Faqs />
      </FaqsWrapper>
    </RecommendationAbsoluteWrapper>
  )
}
