import { useTranslation } from 'react-i18next'
import { formatMoney } from '@/utils'
import { config } from '@/config'
import { Check, Download, X } from 'lucide-react'
import { cloneElement } from 'react'
import { Button } from '@surein/ui'
import { recommendationProductSorter } from '@/pages/RecommendationPage/helpers'
import {
  doesSupportMonthlyPriceCalculation,
  getFinalPrices,
} from '@/modules/recommendation/price'
import { useFeatureFlag } from '@/services/feature-flags'

/**
 * TODO: this should return only data
 *   having presentation logic (react components) mixed in makes it hard to reason about
 */
export const useComparisonTable = (
  data: {
    recommendationProducts: {
      name: string
      amountInsured: number
      deductible: number
      recommendationProductId: string
      parametersValues: {
        de: Record<string, string>
        en: Record<string, string>
      }
      yesNoValues: Record<string, 0 | 1>
      grossPrice: number
      paymentPeriod: string
      carrierData: {
        logoUrl: string
      }
      discountValue: number
      recommendationProductFiles: { fileId: string; name: string }[]
      amountInsuredUnlimited: boolean
    }[]
    visibleAttributes: string[]
    bestChoice: string
  },
  downloadFileMutation: (file: { fileId: string; name: string }) => void,
) => {
  const { t, i18n } = useTranslation()
  const locale = i18n.language as 'en' | 'de'
  const { recommendationProducts, visibleAttributes, bestChoice } = data
  const { data: monthlyPriceFeatureFlag } = useFeatureFlag(
    'offer-price-monthly',
  )

  const items = recommendationProductSorter(recommendationProducts).map(
    (product) => {
      const {
        recommendationProductId,
        parametersValues,
        yesNoValues,
        grossPrice,
        paymentPeriod,
        carrierData,
        discountValue,
        recommendationProductFiles = [],
        amountInsuredUnlimited,
        name,
      } = product

      const { finalPrice, discountedPrice } = getFinalPrices(
        grossPrice,
        discountValue,
        paymentPeriod ?? 'yearly',
        monthlyPriceFeatureFlag?.state,
      )

      const downloadAllFiles = () => {
        recommendationProductFiles.forEach((file) => {
          downloadFileMutation({
            fileId: file?.fileId,
            name: file?.name,
          })
        })
      }

      const formattedPaymentPeriod = monthlyPriceFeatureFlag?.state
        ? t('paymentPeriodOptions.monthly').toLowerCase()
        : t(`paymentPeriodOptions.${paymentPeriod}`).toLowerCase()

      const header = {
        name,
        title: `${formatMoney(finalPrice)}`,
        subtitle: `/ ${formattedPaymentPeriod}`,
        logo: `${config.cdn}/${carrierData.logoUrl}`,
        annotation: discountValue
          ? `${formatMoney(discountedPrice)}`
          : undefined,
        annotationText: discountValue
          ? `${discountValue}% ${t('common.discount')}`
          : undefined,
        footnote:
          monthlyPriceFeatureFlag?.state &&
          doesSupportMonthlyPriceCalculation(paymentPeriod) &&
          paymentPeriod !== 'monthly'
            ? t(`paid.${paymentPeriod}`)
            : undefined,
      }

      const visibleAttributesArray = visibleAttributes || []
      const details = visibleAttributesArray.map((attr) => {
        const paramValue =
          parametersValues[locale] &&
          parametersValues[locale][attr] !== undefined
            ? parametersValues[locale][attr]
            : ''

        const yesNoComponent =
          // TODO: remove JSX from here
          yesNoValues && yesNoValues[attr] !== undefined ? (
            <div className="flex items-center justify-center">
              {yesNoValues[attr] === 1 ? (
                <Check color="#0FA41E" size="16" />
              ) : (
                <X color="#FA0F17" size="16" />
              )}
            </div>
          ) : null

        if (paramValue !== '') {
          return (
            <div className="flex flex-col items-center justify-center gap-2">
              {yesNoComponent && cloneElement(yesNoComponent)}
              {/* do not display parameter value if attribute value is false */}
              {yesNoValues[attr] === 1 && paramValue}
            </div>
          )
        }

        return yesNoComponent || ''
      })

      // TODO: remove JSX from here
      const deductible = <div>{formatMoney(product.deductible)}</div>
      const amountInsured = (
        <div>
          {amountInsuredUnlimited
            ? t('unlimited')
            : formatMoney(product.amountInsured)}
        </div>
      )
      const download =
        // TODO: remove JSX from here
        recommendationProductFiles.length > 0 ? (
          <Button
            suffixIcon={Download}
            shape="square"
            onClick={downloadAllFiles}
            variant="muted"
          >
            {t('download')}
          </Button>
        ) : null

      return {
        id: recommendationProductId,
        header,
        details: [amountInsured, deductible, ...details, download],
        actionLabel: t('getCoverage'),
        isActive: product.recommendationProductId === bestChoice,
      }
    },
  )

  if (items.length === 3) {
    const bestChoiceIndex = items.findIndex((item) => item.isActive)
    const middleIndex = 1

    if (bestChoiceIndex !== middleIndex) {
      const [bestChoiceItem] = items.splice(bestChoiceIndex, 1)
      items.splice(middleIndex, 0, bestChoiceItem)
    }
  }

  return {
    items,
  }
}
