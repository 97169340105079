import { Column, ImageFromCdn, Typography } from '@/components'
import { Button, Card } from '@surein/ui'
import styled from 'styled-components'
import { CountdownComponent } from './components/Countdown'
import { themeBreakpointDown } from '@/utils'
import { useTranslation } from 'react-i18next'

const BookAppointmentCard = styled(Card)`
  display: flex;
  gap: 8px;

  ${themeBreakpointDown('sm')} {
    flex-direction: column;
  }
`

const ImageContainer = styled.div`
  flex: 1;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 224px;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
  }

  ${themeBreakpointDown('sm')} {
    img {
      object-position: center;
    }
  }
`

const LeftSection = styled(Column)`
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  ${themeBreakpointDown('sm')} {
    padding: 24px 24px 0 24px;
  }
`

type BookAppointmentProps = {
  targetDateInMs: number
  onCtaClick: () => void
}

export const BookAppointment = ({
  targetDateInMs = 0,
  onCtaClick,
}: BookAppointmentProps) => {
  const { t } = useTranslation()
  return (
    <BookAppointmentCard variant="borderless">
      <LeftSection>
        <Column gap="8px">
          <CountdownComponent targetDateInMs={targetDateInMs} />
          <Typography variant="itemTitle">
            {t('bookAppointmentTitle')}
          </Typography>
          <Typography variant="body">{t('bookAppointmentSubtitle')}</Typography>
        </Column>
        <Button variant="primary" onClick={onCtaClick} shape="square">
          {t('bookAppointmentButton')}
        </Button>
      </LeftSection>
      <ImageContainer>
        <ImageFromCdn name="team-stack" type="webp" fallbackType="png" />
      </ImageContainer>
    </BookAppointmentCard>
  )
}
