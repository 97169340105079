import { Column, Row, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { theme } from '@/theme'
import {
  Frown,
  Megaphone,
  PaintBucket,
  ShieldCheck,
  Stethoscope,
} from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ProtectionItem, WhatIsProtectedSection } from './styles'
import { useCategoryTranslations } from '@/utils/hooks/useCategoryTranslations'
import { Button } from '@surein/ui'

const categoryIcons = {
  LI: [Frown, Stethoscope, PaintBucket, Megaphone],
} as const

interface WhatIsProtectedProps {
  categoryId: string
  buttonText?: string
  onButtonClick?: () => void
  smallTitle?: boolean
}

export const WhatIsProtected = ({
  categoryId,
  buttonText,
  onButtonClick,
  smallTitle,
}: WhatIsProtectedProps) => {
  const { t } = useTranslation()
  const categoryTranslations = useCategoryTranslations(categoryId)
  const categoryUsps: string[] = categoryTranslations.usps

  if (categoryUsps.length === 0 || categoryUsps.every((item) => item === ''))
    return null

  return (
    <WhatIsProtectedSection>
      <Typography variant={smallTitle ? 'body' : 'itemTitle'}>
        {t('whatIsProtected')}
      </Typography>
      <Column gap="12px">
        {categoryUsps.map((usp, index) => {
          const Icon =
            categoryIcons[categoryId as keyof typeof categoryIcons]?.[index] ||
            ShieldCheck
          return usp ? (
            <ProtectionItem key={usp} gap="8px">
              <Icon color={theme.color[ThemeColor.info1]} />
              <Typography variant="description" color={ThemeColor.glass500}>
                {usp}
              </Typography>
            </ProtectionItem>
          ) : null
        })}
      </Column>
      {buttonText && onButtonClick && (
        <Row justifyContent="start">
          <Button variant="primary" shape="square" onClick={onButtonClick}>
            {buttonText}
          </Button>
        </Row>
      )}
    </WhatIsProtectedSection>
  )
}
